import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreementC() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold mt-4 ml-4">Contractor Agreement</h1>

        <p className="p-4 lead" style={{ marginBottom: 100 }}>
          &nbsp;&nbsp;<strong>v.</strong> Being discharged by the Customer in
          part of my non-adherence to the Grooming Standards as per set out in
          Appendix 2, in which I shall be liable to pay ALPHAVIBE a default
          penalty of SGD 10, or SGD 20 if fee of the contract job is greater or
          equal to SGD 10 per hour; and
          <br />
          <br />
          &nbsp;&nbsp;<strong>vi.</strong> Being negligent at the Customer’s
          place, resulting in but not limited to breakages of items, taking of a
          property without the Customer’s express or implied consent, spillages
          on Customers’ shirts, and misconduct at the workplace, in which I
          shall be liable to pay ALPHAVIBE the penalty charges as determined on
          a later date;
          <strong>8)</strong> I also hereby agree that it is reasonable that I
          am liable to pay for any liquidated damages or penalty incurred by me
          within 7 days of such occurrence as stated in Clause 7 of this
          Agreement. I agree and approve that ALPHAVIBE may offset the Losses
          and/or penalty charges in part/whole from the agreed upon contractor
          fees, and I accept that the amount of liquidated damages or penalty
          charges stated either in this agreement or determined by ALPHAVIBE on
          a later date is a genuine estimate of ALPHAVIBE’s loss and I shall
          have no right to request ALPHAVIBE for any proof of loss. I also agree
          to indemnify and keep indemnified ALPHAVIBE against all such Losses.
          <br />
          <br />
          <strong>9)</strong> I will provide personal attires (Refer to Appendix
          2) necessary for the provision of the Services and will be liable, at
          my own cost, to keep any such personal attires in a safe and proper
          operating condition.
          <br />
          <br />
          <strong>10)</strong> I will comply in all material respects with my
          obligations under the PDPA in relation to the management or protection
          of Personal Data which I come into possession with (if any) in the
          provision of the Services under this Agreement. Both during the term
          of this Agreement and following its termination, I must not (unless
          required to do so by law, protected in doing so by a legal right of
          protected disclosure or doing so in properly providing the Services)
          disclose/use any of ALPHAVIBE and/or the Customer’s confidential
          information to any person or for any purposes other than the provision
          of Services under this Agreement.
        </p>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-d")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-b")}
          />
        </div>
      </div>
    </Background>
  )
}
